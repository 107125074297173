.dashboard-container {
  min-height: 82vh;
}

.header-container {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  padding-bottom: 10px; /* Adjust padding bottom as needed */
  border-bottom: 1px solid black; /* Add bottom border */
}

.header-title {
  width: 87%;
  margin-top: 0;
  color : black !important;
}

.smallButton {
  padding: 16px 26px; 
  font-size: 12px; 
  min-width: auto; 
}