/* IRTable.module.css */

.stickyHeader {
  position: sticky;
  top: 0;
  background: white;
  color: black !important;
  /* Text color for headers */
  z-index: 1;
  border:none;
}

.userSuccess
{
  padding-left : "20px";
  padding-bottom : "20px"
}

.centerText {
  text-align: center;
  color: #121926;
}

.expandedRow {
  background-color: #f0f0f0;
  /* Background color for expanded rows */
}

.detailsSection {
  border: 1px solid #ccc;
  /* Example border style */
  padding: 10px;
  margin-top: 10px;
  background-color: #fafafa;
}