.card {
    background-color: #ffffff;
    color: #0a2972;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    background-image: none;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid;
    border-color: #90caf925;
    box-shadow: 0 2px 14px 0 rgb(32 40 45 / 8%);
    /* overflow: scroll; */
}
.card:hover{
    box-shadow: 15px 14px 20px 0 #00000057 !important;
}


.table {
  font-family: Arial, Helvetica, sans-serif;
 
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

tabletr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ebf1ff;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0a2972;
  color: white !important;
  border-spacing: 0px;
  
}

table thead tr{
  border-color: #0000;
}
.calendar-container{
  position: absolute;
    background: white;
    background-color: #fff;
    box-shadow: 0 2px 14px 0 rgb(32 40 45 / 8%);
    padding: 10px;
    z-index: 999;
}
h1{
  line-height: 1 !important;
}


input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use the full viewport height */
      position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #a9a9a973;
}

.loader-container-with-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
      position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 6;
    background: #a9a9a973;
}

.dropdown-text{
  margin:0;
  padding-bottom: 2px;
}
.btn{
  color:#fff;
  background: #0A2972;
  transition: .3s ease-in-out;
}

.btn:hover{
  color:#0A2972;
  background: #fff;
  border: 1px solid #0A2972;
}