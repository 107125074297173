
  .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    z-index: 10; /* Adjust as needed */
    background-color: white; /* Ensure it covers content behind it */
    display: flex;
    justify-content: space-between; /* For close button alignment */
    align-items: center; /* Center items vertically */
    padding-bottom: 6px; /* Add padding as needed */
    padding-top : 8px;
    padding-left : 18px;
    border-bottom: 0.3px solid #a0a0a0;
  }


  .error-message {
    display: flex;
    align-items: center;
  }
  
  .error-icon {
    color: red;
    margin-right: 5px;
  }
  
  .form-content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom : 10px;
    margin-top: 5px;
  }
  
  .form-field {
    margin-bottom: 15px;
  }
  
  .form-subtitle {
    margin-top: 20px;
  }
  
  .form-select {
    margin-bottom: 20px;
  }
  
  .selected-companies {
    margin-top: 20px;
  }
  
  .remove-company-button {
    margin-left: 10px;
  }
  
  .form-actions {
    text-align: right;
    margin-top: 20px;
  }

  .selected-companies ul {
    list-style-type: none;
    padding: 0;
  }
  
  .selected-companies li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .selected-companies .company-info {
    display: flex;
    align-items: center;
  }
  
  .selected-companies .company-name {
    max-width: 200px; /* Set the maximum width you want for the company name */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  
  